import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import FooterUtilitiesNavItems from "@axvdex/components/FooterUtilitiesNavItems";

import { useAppSelector } from "@axvdex/state";
import { selectGlobalSettings } from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";
import { responsiveBreakpoints } from "../constants";
import { ReactComponent as LogoArchway } from "../assets/logos/logo-archway.svg";
import { ReactComponent as LogoNeutron } from "../assets/logos/logo-neutron.svg";
import { ReactComponent as LogoNibiru } from "../assets/logos/logo-nibiru.svg";
//import {ReactComponent as IcnInstagram} from "../assets/icons/icn-instagram.svg";
// import {ReactComponent as IcnMedium} from "../../assets/icons/icn-medium.svg";
import { ReactComponent as IcnDiscord } from "../assets/icons/icn-discord.svg";
import { ReactComponent as IcnTelegram } from "../assets/icons/icn-telegram.svg";
import { ReactComponent as IcnTwitter } from "../assets/icons/icn-twitterx.svg";
// import {ReactComponent as LogoAWS} from "../assets/logos/logo-aws.svg";
import styles from "../styles/FooterNavItems.module.scss";
import FooterUtilitiesMenu from "./FooterUtilitiesMenu";

function FooterNavItems() {
  const { i18 } = useLanguage();

  const FooterSocialLinksList = [
    // {name: i18("Instagram","social.instagram.name"), url: "#", icon: <IcnInstagram/>},
    // {name: i18("Medium","social.medium.name"), url: "#", icon: <IcnMedium/>},
    { name: i18("Discord", "social.discord.name"), url: "https://astrovault.io/discord", icon: <IcnDiscord /> },
    { name: i18("Telegram", "social.telegram.name"), url: "https://astrovault.io/telegram", icon: <IcnTelegram /> },
    { name: i18("Twitter", "social.twitter.name"), url: "https://astrovault.io/twitter", icon: <IcnTwitter /> },
  ];

  const globalSettings = useAppSelector(selectGlobalSettings);

  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  return (
    <>
      <ul className={clsx(styles.footerNavItemsList, "footerNavItemsList")}>
        <li className="footerNavItem">
          <span className="footerNavItemsListText">{i18("Running on", "footerNavItem.runningOn")}</span>
          <a
            href="https://archway.io/"
            title={i18("Archway", "chain.archway.name")}
            rel="noreferrer"
            target="_blank"
            tabIndex={0}
          >
            <LogoArchway />
          </a>
          /
          <a
            href="https://www.neutron.org/"
            title={i18("Neutron", "chain.neutron.name")}
            rel="noreferrer"
            target="_blank"
            tabIndex={0}
          >
            <LogoNeutron />
          </a>
          <>
            /
            <a
              href="https://nibiru.fi/"
              title={i18("Nibiru", "chain.nibiru.name")}
              rel="noreferrer"
              target="_blank"
              tabIndex={0}
            >
              <LogoNibiru />
            </a>
          </>
          {/*<span className="footerNavItemsListText">*/}
          {/*  {t("+")}*/}
          {/*</span>*/}
          {/*<span className="logo-aws">*/}
          {/*  <LogoAWS />*/}
          {/*</span>*/}
        </li>

        <li className="footerNavItem social">
          <span className="footerNavItemsListText">{i18("Contact Astrovault", "footerNavItem.contact")}</span>

          {FooterSocialLinksList.map((link, idx) => (
            <a
              key={idx}
              href={link.url}
              title={link.name}
              rel="noreferrer"
              target="_blank"
              tabIndex={0}
              aria-label={link.name}
            >
              {link.icon}
            </a>
          ))}
        </li>

        {!isMobileBreakpoint && (!globalSettings?.menuPosition || "bottom" === globalSettings.menuPosition) && (
          <li className="footerNavItem utilities">
            <FooterUtilitiesMenu />
          </li>
        )}
      </ul>

      {isMobileBreakpoint && <FooterUtilitiesNavItems />}
    </>
  );
}

export default FooterNavItems;
