import { JSX, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ArrowDown, ArrowUp, Search } from "react-feather";
// import { useMediaQuery } from "usehooks-ts";
import { delay, findIndex, isEqual } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { compareArrayOfObject } from "@axvdex/utils/wallet";
// import { responsiveBreakpoints } from "@axvdex/constants";
import { ReactComponent as IcnSortAsc } from "@axvdex/assets/icons/icn-sort-arrow-down-short-wide-solid.svg";
import { ReactComponent as IcnSortDesc } from "@axvdex/assets/icons/icn-sort-arrow-down-wide-short-solid.svg";
import { ReactComponent as IcnUnsorted } from "@axvdex/assets/icons/icn-sort-unsorted.svg";
import { loadState, persistState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import {
  selectAssets,
  selectChains,
  selectFarms,
  selectFarmsWithBalance,
  selectFavoriteFarms,
} from "@axvdex/state/wallet/walletSelectors";
import useLanguage from "@axvdex/hooks/useLanguage";
// import { getTokenList } from "../tokenListForSelect";
import MainLayout from "@axvdex/layouts/MainLayout";
import { IChain, IFarm } from "@axvdex/utils/interfaces";
import CustomLoader from "../common/CustomLoader";
import StatusMessages from "../StatusMessages";
import FarmCardDetailsModal from "../modals/FarmCardDetailsModal";
import Button from "../common/Button";
import { getTokenList } from "../tokenListForSelect";
import FarmCard from "./FarmCard";
import CustomInputButton from "components/form-element/CustomInputButton";
import CustomSelect from "components/form-element/CustomSelect";
// import PoolCardDetailsModal from "components/modals/PoolCardDetailsModal";
// import PoolCard from "components/pools/PoolCard";
import styles from "styles/Pool.module.scss";

import { useAppSelector } from "state";
import { useQuery } from "@axvdex/hooks/useQuery";

const SortOrderIcon = ({ sortOrder, isDate }: { sortOrder: "desc" | "asc" | "unsorted"; isDate: boolean }) => {
  const icon = (): [JSX.Element, string] => {
    const { i18 } = useLanguage();

    let title = i18("Click to sort high to low", "pools.filter.sortOrder.title.toDesc");
    if (isDate) {
      title = i18("Click to sort latest to oldest", "pools.filter.sortOrder.title.date.toDesc");
    }
    let ariaLabel = i18("Not sorted", "pools.filter.sortOrder.ariaLabel.unsorted");
    let iconEl = <IcnUnsorted data-tooltip-id="tooltip" aria-label={ariaLabel} />;

    if ("desc" === sortOrder) {
      title = i18("Click to sort low to high", "pools.filter.sortOrder.title.toAsc");
      ariaLabel = i18("Sorted high to low", "pools.filter.sortOrder.ariaLabel.desc");
      if (isDate) {
        title = i18("Click to sort oldest to latest", "pools.filter.sortOrder.title.date.toAsc");
        ariaLabel = i18("Sorted latest to oldest", "pools.filter.sortOrder.ariaLabel.date.desc");
      }
      iconEl = <IcnSortDesc data-tooltip-id="tooltip" aria-label={ariaLabel} />;
    } else if ("asc" === sortOrder) {
      title = i18("Click to remove sort direction", "pools.filter.sortOrder.title.toUnsorted");
      ariaLabel = i18("Sorted low to high", "pools.filter.sortOrder.ariaLabel.asc");
      if (isDate) {
        ariaLabel = i18("Sorted oldest to latest", "pools.filter.sortOrder.ariaLabel.date.asc");
      }
      iconEl = <IcnSortAsc data-tooltip-id="tooltip" aria-label={ariaLabel} />;
    }

    return [iconEl, title];
  };

  return icon();
};

const setupFilterByChain = (farms: { [key: string]: IFarm }, chains: { [key: string]: IChain }) => {
  const options: Set<string> = new Set();
  Object.values(farms).forEach(farm => {
    options.add(farm.contextChainId);
  });
  return Array.from(options).map(chain => ({ label: chains[chain].displayName, value: chain }));
};

function FarmsTable() {
  const { i18 } = useLanguage();

  const routeParams = useParams();
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const [filterChainList, setFilterChainList] = useState([
    { label: i18("All chains", "farms.filter.filterByChain.all"), value: "all" },
  ]);

  const [topFilter, setTopFilter] = useState(() => {
    const tf = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable).topFilter;
    return tf ? tf : "all";
  });
  const [selectedChain, setSelectedChain] = useState(filterChainList[0]);
  const [selectedSort, setSelectedSort] = useState(() => {
    const ss = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable).selectedSort;
    return ss ? ss : null;
  });
  const [sortOrder, setSortOrder] = useState(() => {
    const so = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable).sortOrder;
    return so ? so : "desc";
  });

  const [iconEl, title] = SortOrderIcon({ sortOrder: sortOrder, isDate: "createdAt" === selectedSort?.value });
  const SortByList = [
    {
      label: i18("TVL", "pools.filter.sortBy.tvl.label"),
      value: "farm_tvl",
      optionPrefix: "farm_tvl" === selectedSort?.value ? iconEl : null,
      tooltip:
        "farm_tvl" === selectedSort?.value
          ? title
          : i18("Sort high to low by TVL", "pools.filter.sortBy.tvl.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
    {
      label: i18("APR", "pools.filter.sortBy.apr.label"),
      value: "apr",
      optionPrefix: "apr" === selectedSort?.value ? iconEl : null,
      tooltip:
        "apr" === selectedSort?.value
          ? title
          : i18("Sort high to low by APR", "pools.filter.sortBy.apr.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
    {
      label: i18("Added", "pools.filter.sortBy.added.label"),
      value: "createdAt",
      optionPrefix: "createdAt" === selectedSort?.value ? iconEl : null,
      tooltip:
        "createdAt" === selectedSort?.value
          ? title
          : i18("Sort latest to oldest added date", "pools.filter.sortBy.added.tooltip.default"),
      /* Uncomment this to force open tooltip
      openTooltip: true,
      /**/
    },
  ];

  const processFarms = (farms, assets) => {
    return Object.keys(farms)
      ?.filter(farmKey => !farms[farmKey].isHidden)
      .map(farmAddress => {
        const incAsset = farms[farmAddress].config?.inc_token;
        const farm_tvl =
          assets[incAsset].price * (Number(farms[farmAddress].tvl) / Math.pow(10, assets[incAsset].decimals));
        const assetsInvolved = [assets[incAsset]];

        farms[farmAddress].reward_sources?.forEach((rewardSource: any) => {
          const assetId =
            rewardSource.reward_asset.token?.contract_addr || rewardSource.reward_asset.native_token?.denom;
          assetsInvolved.push(assets[assetId]);
        });

        return {
          ...farms[farmAddress],
          farm_tvl,
          assetsInvolved,
          apr: farms[farmAddress].percentageAPRs
            ? farms[farmAddress].percentageAPRs.reduce((sum, source) => sum + source, 0) || 0
            : 0,
        };
      });
  };

  const [loadFarm, setLoadFarm] = useState(true);
  const [tokenList, setTokenList] = useState([]);
  const [farmsData, setFarmsData] = useState([]);
  const chains = useAppSelector(selectChains);
  const farms = useAppSelector(selectFarms, isEqual);
  const assets = useAppSelector(selectAssets, isEqual);
  const farmsWithBalance = useAppSelector(selectFarmsWithBalance, isEqual);
  const favoriteList = useAppSelector(selectFavoriteFarms, isEqual);
  //const isSmallBreakpoint = useMediaQuery(responsiveBreakpoints.mobile);

  const [farmSearchMenuIsOpen, setFarmSearchMenuIsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [fuzzySearchString, setFuzzySearchString] = useState("");
  const [assetSearchOption, setAssetSearchOption] = useState("");

  const [selectedFarm, setSelectedFarm] = useState<string>();

  const handleToggleFarmModal = useCallback((farmAddress: string) => {
    setSelectedFarm(farmAddress);
  }, []);

  const handleCloseFarmModal = useCallback(() => {
    navigate("/farm", { replace: true });
    setSelectedFarm("");
  }, []);

  useEffect(() => {
    setTokenList(getTokenList(assets));
    const farmsData = processFarms(farms, assets);
    setFarmsData(farmsData);
    setFilterChainList([
      { label: i18("All chains", "farms.filter.filterByChain.all"), value: "all" },
      ...setupFilterByChain(farms, chains),
    ]);
  }, [farms, assets, chains]);

  useEffect(() => {
    if (location?.state?.farmAddress) {
      const { farmAddress } = location.state;
      navigate(location.pathname, { replace: true });
      delay(() => setSelectedFarm(farmAddress), 50);
    }
  }, []);

  useEffect(() => {
    if (farmsData.length && routeParams?.farmAddress) {
      const { farmAddress } = routeParams;
      setSelectedFarm(farmAddress);
    }
  }, [farmsData]);

  // handle chainId coming in with params
  useEffect(() => {
    const searchParamValue = query.get("chainId");
    if (searchParamValue && farmsData?.length > 0) {
      const selectableOptions = setupFilterByChain(farms, chains);
      const chainIndex = selectableOptions.findIndex(option => option.value === searchParamValue);
      if (-1 !== chainIndex) {
        setSelectedChain(selectableOptions[chainIndex]);
      }
      navigate("/farm", { replace: true }); // replace the URL to remove the chainId param so this does not execute again on rerenders
    }
  }, [farmsData]);

  const handleSortChange = e => {
    if (selectedSort?.value === e?.value) {
      const farmsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable);
      const newSortOrder = "unsorted" === sortOrder ? "desc" : "desc" === sortOrder ? "asc" : "unsorted";
      farmsTable.sortOrder = newSortOrder;
      persistState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable, farmsTable);
      setSortOrder(newSortOrder);
      return;
    }
    setSortOrder("desc");
    const farmsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable);
    farmsTable.selectedSort = e;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable, farmsTable);
    setSelectedSort(e);
  };

  const sortFarms = farmResults => {
    if (!selectedSort || !sortOrder || "unsorted" === sortOrder) {
      return compareArrayOfObject(farmResults, "label", true, false);
    }
    if ("createdAt" === selectedSort.value) {
      return compareArrayOfObject(farmResults, selectedSort.value, "desc" === sortOrder, false, true);
    }
    return compareArrayOfObject(farmResults, selectedSort.value, "desc" === sortOrder);
  };

  const displayFarms = useMemo(() => {
    const farmsDataByAssetName = farmsData.filter(
      farm =>
        farm.assetsInvolved.find(({ symbol }) => symbol === assetSearchOption) ||
        (fuzzySearchString && farm.label.toLowerCase().includes(fuzzySearchString.toLowerCase()))
    );

    const transformedFarmDetails = farmsDataByAssetName.length
      ? [...farmsDataByAssetName]
      : !assetSearchOption && !fuzzySearchString
      ? [...Object.values(farmsData)]
      : [];

    let transformedFarms = [...transformedFarmDetails],
      ftype = "";

    if ("my_farms" === topFilter) {
      ftype = "active ";
      transformedFarms = [
        ...transformedFarms.filter(farm => {
          return farmsWithBalance.find(({ contract_addr }) => contract_addr === farm.address);
        }),
      ];
    } else if ("favorites" === topFilter) {
      ftype = "favourite ";
      transformedFarms = [...transformedFarms.filter(farm => favoriteList.includes(farm.address))];
    } else {
      transformedFarms = [...transformedFarms];
    }

    if (selectedChain?.value !== "all") {
      transformedFarms = [
        ...transformedFarms.filter(farm => {
          return farm.contextChainId === selectedChain.value;
        }),
      ];
    }

    const sortedFarms = sortFarms(transformedFarms);

    const farmCards = [];
    const deprecatedFarmCards = [];

    sortedFarms.forEach((currentFarm, index) => {
      if ("deprecated" === currentFarm.uiSection) {
        deprecatedFarmCards.push(
          <FarmCard
            key={index}
            farm={currentFarm}
            onClick={() => handleToggleFarmModal(currentFarm.address)}
            isSelected={selectedFarm === currentFarm.address}
            favorite={favoriteList.includes(currentFarm.address)}
          />
        );
      } else {
        farmCards.push(
          <FarmCard
            key={index}
            farm={currentFarm}
            onClick={() => handleToggleFarmModal(currentFarm.address)}
            isSelected={selectedFarm === currentFarm.address}
            favorite={favoriteList.includes(currentFarm.address)}
          />
        );
      }
    });

    if (0 === farmCards.length && 0 === deprecatedFarmCards.length) {
      return {
        farmCards: [i18(`Your ${ftype}farms will show here...`, `farms.topFilter.placeholder`, { ftype })],
        deprecatedFarmCards: [],
      };
    }
    setLoadFarm(false);

    return {
      farmCards: farmCards,
      deprecatedFarmCards: deprecatedFarmCards,
    };
  }, [
    farmsData,
    selectedSort,
    selectedChain,
    sortOrder,
    favoriteList,
    farmsWithBalance,
    selectedFarm,
    topFilter,
    fuzzySearchString,
    assetSearchOption,
  ]);

  const onInputChange = (value: string) => {
    setSearchString(value);
    setFarmSearchMenuIsOpen(!!value);
  };

  const handleSearchKeyDown = event => {
    if ("Enter" === event.key) {
      setFuzzySearchString(event.target.value);
      setFarmSearchMenuIsOpen(false);
      event.preventDefault();
    }
  };

  const handleSearchOnChange = (option, { action }) => {
    if ("select-option" === action) {
      setAssetSearchOption(option.value);
      setFuzzySearchString("");
    }
    if ("clear" === action) {
      setAssetSearchOption("");
    }
  };

  const handleTopFilterChange = e => {
    const filterValue = e.target.value;
    if ("all" === filterValue) {
      setFuzzySearchString("");
      setAssetSearchOption("");
    }
    updateTopFilter(filterValue);
  };

  const updateTopFilter = value => {
    const farmsTable = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable);
    farmsTable.topFilter = value;
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.farmsTable, farmsTable);
    setTopFilter(value);
  };

  return (
    <>
      <MainLayout
        pageClass={clsx(styles.pool, "pool")}
        headTitle={i18("Farm", "farms.headTitle")}
        headDescription={i18("Farms of Astrovault", "farms.headDescription")}
      >
        <StatusMessages />
        <section className="poolSectionHeader withGradientBorderBottom">
          <div className="poolSectionHeaderTitle flexbox">
            <h1>{i18("Farms", "farms.title")}</h1>

            <fieldset>
              <legend className="visuallyHidden">{i18("Display", "farms.topFilter.legend")}</legend>
              <div className="btnGroup">
                <CustomInputButton
                  type="radio"
                  name="farm_sort"
                  labelText={i18("All", "farms.topFilter.all")}
                  id="all"
                  value="all"
                  checked={"all" === topFilter}
                  onChange={() => {}}
                  onClick={handleTopFilterChange}
                />
                <CustomInputButton
                  type="radio"
                  id="favorites"
                  name="pool_sort"
                  labelText="Favourites"
                  value="favorites"
                  checked={"favorites" === topFilter}
                  onChange={() => {}}
                  onClick={() => updateTopFilter("favorites")}
                />
                <CustomInputButton
                  type="radio"
                  id="my_farms"
                  name="farm_sort"
                  labelText={i18("My Farms", "farms.topFilter.myFarms")}
                  value="my_farms"
                  checked={"my_farms" === topFilter}
                  onChange={() => {}}
                  onClick={() => updateTopFilter("my_farms")}
                />
              </div>
            </fieldset>
          </div>

          <div className="poolSectionHeaderFilters gridBox">
            {
              // <CustomSelect
              //   name="pool_filter_by_status"
              //   labelText="Filter by Status"
              //   hiddenLabel={true}
              //   placeholder="Status"
              //   items={DummyFilterList}
              //   isClearable
              // />
              //
            }

            <CustomSelect
              name="farm_sort"
              labelText={i18("Sort", "farms.filter.sort.label")}
              hiddenLabel={true}
              placeholder={i18("Sort", "farms.filter.sort.placeholder")}
              items={SortByList}
              value={
                selectedSort?.value
                  ? SortByList[findIndex(SortByList, item => item.value === selectedSort?.value)]
                  : null
              }
              onChange={handleSortChange}
              isClearable
            />

            <CustomSelect
              name="farm_chain"
              labelText={i18("Chain", "farms.filter.chain.label")}
              hiddenLabel={true}
              placeholder={i18("Chain", "farms.filter.chain.placeholder")}
              items={filterChainList}
              value={selectedChain}
              onChange={(e: any) => setSelectedChain(e)}
            />

            <CustomSelect
              name="farm_search"
              labelText={i18("Search", "farms.filter.search.label")}
              hiddenLabel={true}
              placeholder={i18("Search", "farms.filter.search.placeholder")}
              items={tokenList}
              customSelectIcon={<Search />}
              hideChevronIcon={true}
              onInputChange={onInputChange}
              inputValue={searchString}
              onChange={handleSearchOnChange}
              onKeyDown={handleSearchKeyDown}
              isClearable
              menuIsOpen={farmSearchMenuIsOpen}
            />
          </div>
        </section>

        <section className="poolSectionCardGrid gridBox">
          {loadFarm ? <CustomLoader size="sm" /> : displayFarms.farmCards}
        </section>
        {0 !== farmsData.length && (
          <FarmCardDetailsModal
            openModal={!!selectedFarm}
            onCloseModal={handleCloseFarmModal}
            farmAddress={selectedFarm}
            farms={farmsData}
          />
        )}
        <DeprecatedFarms deprecatedFarms={displayFarms?.deprecatedFarmCards} />
      </MainLayout>
    </>
  );
}

const DeprecatedFarms = ({ deprecatedFarms }: any) => {
  const { i18 } = useLanguage();
  const [displayDeprecated, setDisplayDeprecated] = useState(false);

  if (!deprecatedFarms || !deprecatedFarms.length) return null;
  return (
    <div style={{ marginTop: "2em" }} className="withGradientBorderTop">
      <div style={{ paddingTop: "1.5em" }}>
        <section
          className="poolSectionHeader "
          style={{ paddingTop: "0em", paddingBottom: "0em", marginBottom: "1em" }}
        >
          <div className="poolSectionHeaderTitle flexbox">
            <h2>{i18("Deprecated", "pools.deprecated")}</h2>
            <Button onClick={() => setDisplayDeprecated(!displayDeprecated)}>
              {displayDeprecated ? <ArrowUp /> : <ArrowDown />}
            </Button>
          </div>
        </section>
        {displayDeprecated ? (
          <section className="farmSectionCardGrid gridBox">{deprecatedFarms}</section>
        ) : (
          i18("Click the down arrow button to show the deprecated farms", "farms.deprecatedText")
        )}
      </div>
    </div>
  );
};

export default FarmsTable;
